import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { type OpenGraph } from 'next-seo/lib/types'

import { Box } from '@mui/material'

import { getEnv } from '@/envs'
import { WithHubSpotProvider } from '@/features/HubSpotForm/providers/WithHubSpotProvider'
import { ProductProvider } from '@/providers/p/ProductProvider'
import ProductDetailsTemplate from '@/templates/ProductDetailsPage'
import type { ProductDetailsNextPageNoErrorProps } from '@/types'
import { getSectionsToHide } from '@/utils/plain/getSectionsToHide'

const ProductDetailsPage = (props: ProductDetailsNextPageNoErrorProps) => {
  const { product } = props
  const { seo, name, images } = product
  const { metaTitle, metaDescription } = seo

  const { locale, asPath } = useRouter()

  const url = new URL(`/${locale}${asPath}`, getEnv('NEXT_PUBLIC_HOST_BASE_URL'))

  const openGraphDetails: OpenGraph = {
    url: url.toString(),
    type: 'product',
    locale,
    siteName: 'Knauf',
    images: [
      {
        url: images[0].url,
        alt: name,
      },
    ],
  }

  return (
    <Box>
      <NextSeo title={metaTitle} description={metaDescription} openGraph={openGraphDetails} />
      <ProductProvider product={product}>
        <WithHubSpotProvider>
          <ProductDetailsTemplate sectionsToHide={getSectionsToHide(locale)} />
        </WithHubSpotProvider>
      </ProductProvider>
    </Box>
  )
}

export default ProductDetailsPage
