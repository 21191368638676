import NextLink from 'next/link'

import Breadcrumbs from '@knauf-group/ct-designs/components/core/Breadcrumbs/Breadcrumbs'

import { useBreadcrumbsLinks } from '@/components/Breadcrumbs'
import { useProduct } from '@/providers/p/ProductProvider'
import { useT } from '@/utils/frontend/useT'
import { isEmpty } from '@/utils/plain/isEmpty'

export const ProductDetailsBreadcrumb = () => {
  const { product } = useProduct()
  const { name, category, categorySlug } = product

  const { t } = useT({ keyPrefix: 'common' })

  const breadcrumbsLinks = useBreadcrumbsLinks({
    page: 'product',
    categoryName: isEmpty(category) ? t('all-products-category') : category,
    categorySlug: isEmpty(categorySlug) ? '' : categorySlug,
    productName: name,
  })

  const goBackLabel = isEmpty(category)
    ? t('breadcrumbs.back-to-all-products')
    : t('breadcrumbs.back-to-category', { categoryName: category })

  return (
    <Breadcrumbs
      links={breadcrumbsLinks}
      nextLinkComponent={NextLink}
      backLabel={goBackLabel}
      dataCy="breadcrumbs-back-to-category"
    />
  )
}
