import type { ButtonProps } from '@mui/material'
import { Button } from '@mui/material'

import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'

import { useHubSpotFormsContext } from '../providers/HubSpotFormsProvider'

export const InquireNowFormTrigger = (props: ButtonProps) => {
  const { trackClickEvent } = useProductDetailAnalyticsTracking()
  const { setIsOpenForm } = useHubSpotFormsContext()
  const openForm = () => {
    setIsOpenForm(true)
  }

  const { t } = useT({ keyPrefix: 'product' })

  return (
    <Button
      {...props}
      onClick={(e) => {
        trackClickEvent(t('inquire-now'), 'hubspot_form', 'internal_cta_click')
        props?.onClick?.(e)
        openForm()
      }}
    >
      {t('inquire-now')}
    </Button>
  )
}
