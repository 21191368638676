import logger from '@knauf-group/ct-shared-nextjs/logger'
import { useFlag, useVariant } from '@unleash/nextjs'
import { z } from 'zod'

const PayloadSchema = z.object({
  portalId: z.string(),
  orgIdFormIdMap: z.record(z.string()),
})

export const useInquireNowHubspotFormUnleash = (): {
  isActive: boolean
  settings?: z.infer<typeof PayloadSchema>
} => {
  const flag = useFlag('hubspot-form-inquire-now')
  const flagVariants = useVariant('hubspot-form-inquire-now')

  // is flag is false, we return false
  if (!flag) return { isActive: false }

  // if we don't have any variants configured we return false
  if (!flagVariants.enabled) return { isActive: false }

  // we parce the payload that should be of VariantPayload type
  try {
    const payload = JSON.parse(flagVariants.payload?.value ?? '')
    if (!payload) return { isActive: false }

    // validate the paylaod
    const parsedPayload = PayloadSchema.parse(payload)

    return {
      isActive: true,
      settings: parsedPayload,
    }
  } catch (error: any) {
    logger.error(error?.message)
    return { isActive: false }
  }
}
