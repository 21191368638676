import type { PropsWithChildren } from 'react'

import { InquireNowFormModal } from '../components/InquireNowFormModal'
import { useGetHubSpotProps } from '../hooks/useGetHubSpotProps'
import { HubSpotFormsProvider } from './HubSpotFormsProvider'

export const WithHubSpotProvider = ({ children }: PropsWithChildren) => {
  const props = useGetHubSpotProps()

  if (!props) {
    return children
  }

  return (
    <HubSpotFormsProvider>
      {children}
      <InquireNowFormModal {...props} />
    </HubSpotFormsProvider>
  )
}
