import { useRouter } from 'next/router'

import { useProduct } from '@/providers/p/ProductProvider'
import { splitLocale } from '@/utils/plain/splitLocale'

import { useInquireNowHubspotFormUnleash } from './useInquireNowHubspotFormUnleash'

const getFormId = (orgId: string, country: string, orgIdFormIdMap: Record<string, string>) => {
  const orgIdWithCountryKey = `${orgId}_${country}`

  if (orgIdWithCountryKey in orgIdFormIdMap) {
    return orgIdFormIdMap[orgIdWithCountryKey]
  }

  return undefined
}

export const useGetHubSpotProps = () => {
  const { isActive, settings } = useInquireNowHubspotFormUnleash()
  const { product } = useProduct()
  const { locale, asPath } = useRouter()
  const { country } = splitLocale(locale)

  if (!isActive || !settings) {
    return undefined
  }
  const productId = product.productNr
  const orgId = productId?.split('_')?.[1]
  const { portalId, orgIdFormIdMap } = settings

  const formId = getFormId(orgId, country, orgIdFormIdMap)

  if (!formId) {
    return undefined
  }

  return {
    portalId,
    formId,
    productName: product.name,
    productUrl: asPath,
  }
}
