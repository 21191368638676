import type { Locale } from 'next/router'

import { uniq } from 'lodash'

import type { Section } from '@/constants'
import { SECTIONS_TO_HIDE } from '@/constants'

export const parseSectionsToHideEnvVar = (configFromEnvVar: string = SECTIONS_TO_HIDE) => {
  // `configFromEnvVar` is passed as an argument for easier testing
  const sectionsHiddenByLocale = {} as Record<string, Section[]>

  if (configFromEnvVar) {
    const [globalConfig, ...configs] = configFromEnvVar
      .split(';')
      .map((config) => config.trim())

    const sectionsHiddenGlobally = globalConfig
      .split(',')
      .map((section) => section.trim() as Section)
      .filter((section) => Boolean(section)) // no empty string

    configs.forEach((config) => {
      const [locale, sections] = config.split(':').map((part) => part.trim())

      if (!sections) return // locale specified but no sections list is given

      sectionsHiddenByLocale[locale] = sections
        .split(',')
        .map((section) => section.trim() as Section)
        .filter((section) => Boolean(section)) // no empty string
    })

    sectionsHiddenByLocale['*'] = sectionsHiddenGlobally
  }

  return sectionsHiddenByLocale
}

const sectionsToHideConfig = parseSectionsToHideEnvVar()

export const getSectionsToHide = (locale: Locale) => {
  const mergedConfig = (sectionsToHideConfig['*'] ?? []).concat(
    sectionsToHideConfig[locale] ?? [],
  )

  return uniq(mergedConfig)
}
