import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import Script from 'next/script'

import { type HubspotFormProps } from '../types/hubspot.types'

const HubSpotContext = createContext({
  isScriptLoaded: false,
  isScriptError: false,
  isOpenForm: false,
  setIsOpenForm: (_v: boolean) => {},
})

export const useHubSpotFormsContext = () => useContext(HubSpotContext)

export const HubSpotFormsProvider = ({ children }: PropsWithChildren) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [isScriptError, setIsScriptError] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)

  const memoizedProviderVals = useMemo(
    () => ({ isScriptLoaded, isScriptError, isOpenForm, setIsOpenForm }),
    [isScriptLoaded, isScriptError, isOpenForm, setIsOpenForm],
  )

  return (
    <>
      <Script
        src="https://js.hsforms.net/forms/v2.js"
        crossOrigin="anonymous"
        onReady={() => {
          setIsScriptLoaded(true)
        }}
        onError={() => {
          setIsScriptError(true)
        }}
      />
      <HubSpotContext.Provider value={memoizedProviderVals}>{children}</HubSpotContext.Provider>
    </>
  )
}

export const useHubSpotForm = (props: HubspotFormProps) => {
  const [formCreated, setFormCreated] = useState(false)

  const { isScriptLoaded, isScriptError } = useHubSpotFormsContext()

  useEffect(() => {
    if (isScriptLoaded && !isScriptError && window.hbspt.forms && !formCreated) {
      window.hbspt.forms.create(props)
      setFormCreated(true)
    }
  }, [isScriptLoaded, isScriptError, formCreated, props])

  return { formCreated }
}
