import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useT } from '@/utils/frontend/useT'

import { useHubSpotForm, useHubSpotFormsContext } from '../providers/HubSpotFormsProvider'

const InquireNowFormModalId = 'inquiere-now-hubspot-form'

const modifyHiddenInput = (form: HTMLFormElement, name: string, newValue: string) => {
  try {
    // @ts-expect-error
    form.elements.namedItem(name).value = newValue
  } catch (error) {
    console.warn('Error modifying hidden input', error)
  }
}

export const InquireNowFormModal = ({
  formId,
  portalId,
  productName,
  productUrl,
}: {
  portalId: string
  formId: string
  productUrl: string
  productName: string
}) => {
  const { isOpenForm, setIsOpenForm } = useHubSpotFormsContext()
  const closeForm = () => {
    setIsOpenForm(false)
  }

  useHubSpotForm({
    portalId,
    formId,
    target: `#${InquireNowFormModalId}`,
    onFormReady(form) {
      if (!form) return
      modifyHiddenInput(form, 'test_url', productUrl)
      modifyHiddenInput(form, 'test_product_name', productName)
    },
  })

  const { t } = useT({ keyPrefix: 'product' })
  const { isMobile } = useBreakpoints()

  return (
    <Dialog
      scroll="paper"
      open={isOpenForm}
      onClose={closeForm}
      fullWidth
      keepMounted
      fullScreen={isMobile}
    >
      <DialogTitle>{t('inquire-now')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeForm}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <div id={InquireNowFormModalId} />
      </DialogContent>
    </Dialog>
  )
}
