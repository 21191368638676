import dynamic from 'next/dynamic'

import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import { ArrowForward } from '@mui/icons-material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Button, Chip, Stack } from '@mui/material'

import { VARIANT_HREF } from '@/constants'
import { InquireNowFormTrigger } from '@/features/HubSpotForm/components/InquireNowFormTrigger'
import { useGetHubSpotProps } from '@/features/HubSpotForm/hooks/useGetHubSpotProps'
import { useProduct } from '@/providers/p/ProductProvider'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useCurrentUrlWithoutHashtag } from '@/utils/frontend/useBaseUrl'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useT } from '@/utils/frontend/useT'
import { isEmpty, isNotEmpty } from '@/utils/plain/isEmpty'

import { BulletList } from '../../BulletList'
import type Certificates from '../../Certificates'
import type FieldOfApplication from '../../FieldOfApplication'
import { NameDescriptionDIY } from '../FirstViewportSection/NameDescriptionDIY'
import type FirstViewportDownloadSection from './FirstViewportDownloadSection'

const DynamicFirstViewportDownloadSection = dynamic<
  React.ComponentProps<typeof FirstViewportDownloadSection>
>(() => import('./FirstViewportDownloadSection'))

const DynamicFieldOfApplication = dynamic<React.ComponentProps<typeof FieldOfApplication>>(
  () => import('../../FieldOfApplication'),
)

const DynamicCertificates = dynamic<React.ComponentProps<typeof Certificates>>(
  () => import('../../Certificates'),
)

const MAX_NUM_OF_TOP_PROPERTIES = 4

const dataCy = 'ProductInformationSection'

export const ProductInformationSection = () => {
  const { product } = useProduct()
  const { trackClickEvent } = useProductDetailAnalyticsTracking()
  const shouldRenderHubSpotTrigger = Boolean(useGetHubSpotProps())

  const currentUrl = useCurrentUrlWithoutHashtag()

  const {
    properties,
    specificFieldsOfApplication,
    firstViewportDocuments,
    storeAvailability,
    variants,
  } = product

  const hasVariants = (() => {
    if (variants == null || isEmpty(variants)) return false
    if (isEmpty(variants.data)) return false
    if (isEmpty(variants.headers)) return false
    return true
  })()

  const { t } = useT({ keyPrefix: 'product' })

  const { isDesktop, isNotDesktop } = useBreakpoints()
  const topProperties = properties.slice(0, MAX_NUM_OF_TOP_PROPERTIES)

  return (
    <Stack spacing={3}>
      {isDesktop ? <NameDescriptionDIY /> : null}

      {isDesktop && shouldRenderHubSpotTrigger ? (
        <div>
          <InquireNowFormTrigger />
        </div>
      ) : null}

      {/* properties */}
      {isNotEmpty(topProperties) && (
        <BulletList items={topProperties} dataCy={`${dataCy}-properties`} />
      )}

      {/* First View Downloads */}
      {isNotEmpty(firstViewportDocuments) && <DynamicFirstViewportDownloadSection />}

      {/* Field of application */}
      {isNotEmpty(specificFieldsOfApplication) && <DynamicFieldOfApplication />}

      {/* See all variants */}
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {hasVariants ? (
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            component="a"
            onClick={() => {
              trackClickEvent(
                t('field-of-application-download-text'),
                currentUrl + VARIANT_HREF,
                'internal_cta_click',
              )
            }}
            data-cy={`${dataCy}-see-all-variants`}
            href={VARIANT_HREF}
            sx={{
              width: {
                xs: '100%',
                lg: 'auto',
              },
            }}
            endIcon={<ArrowForward sx={RTLIconStyles} />}
          >
            {t('field-of-application-download-text')}
          </Button>
        ) : null}
        {isNotEmpty(storeAvailability) && (
          <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
            <Stack direction="column" spacing={1}>
              {storeAvailability.map((item) => (
                <Chip
                  key={item}
                  variant="outlined"
                  color="secondary"
                  icon={<LocationOnOutlinedIcon color="secondary" />}
                  label={item}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
      {isNotDesktop ? <DynamicCertificates /> : null}
    </Stack>
  )
}
