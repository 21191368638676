import { LocationOnOutlined } from '@mui/icons-material'
import { Chip, Stack, Typography } from '@mui/material'

import { useProduct } from '@/providers/p/ProductProvider'
import { useT } from '@/utils/frontend/useT'

const dataCy = 'NameDescriptionDIY'

export const NameDescriptionDIY = () => {
  const { product } = useProduct()
  const { name, shortDescription, isDIY } = product
  const { t } = useT({ keyPrefix: 'common' })

  return (
    <Stack spacing={1}>
      <Typography component="h1" variant="h5" data-cy={`${dataCy}-title`}>
        {name}
      </Typography>
      <Typography variant="body2bold" data-cy={`${dataCy}-shortDescription`}>
        {shortDescription}
      </Typography>
      {isDIY ? (
        <Chip
          variant="outlined"
          color="secondary"
          icon={<LocationOnOutlined color="secondary" />}
          label={t('DIY-label')}
          sx={{
            alignSelf: 'flex-start',
          }}
        />
      ) : null}{' '}
    </Stack>
  )
}
